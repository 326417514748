const PREFIX_OIDC_SERVER = process.env.PREFIX_OIDC_SERVER || 'https://secure-dev.lefebvre-dalloz.fr/as'
const LOCAL_REACT_APP_PREFIX = process.env.LOCAL_REACT_APP_PREFIX || 'https://omdd.local:3000'
const TEST_CLIENT_ID = process.env.TEST_CLIENT_ID || 'f7236e03-6271-42b3-8a30-dc7f5a25c819'

export const OidcConfigPing = {
  client_id: TEST_CLIENT_ID,
  redirect_uri: LOCAL_REACT_APP_PREFIX + '/',
  post_logout_redirect_uri: LOCAL_REACT_APP_PREFIX,
  response_type: 'code',
  scope: 'openid profile email',
  authority: PREFIX_OIDC_SERVER + '/authorize',
  metadata: {
    issuer: PREFIX_OIDC_SERVER,
    authorization_endpoint: PREFIX_OIDC_SERVER + '/authorize',
    token_endpoint: PREFIX_OIDC_SERVER + '/token',
    userinfo_endpoint: PREFIX_OIDC_SERVER + '/userinfo',
    end_session_endpoint: `${PREFIX_OIDC_SERVER}/logout?post_logout_redirect_uri=${LOCAL_REACT_APP_PREFIX}`,
    jwks_uri: PREFIX_OIDC_SERVER + '/jwks',
  },
}
