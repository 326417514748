const PREFIX_OIDC_SERVER =
  window.env.PREFIX_OIDC_SERVER || 'https://login.microsoftonline.com/1d99125f-df57-4e5c-b504-47a38747867b'
const LOCAL_REACT_APP_PREFIX = window.env.LOCAL_REACT_APP_PREFIX || 'https://omdd.local:3000'
const TEST_CLIENT_ID = window.env.CLIENT_ID || '3fe75516-c88f-4221-8e27-be98202f4b28'
const USER_INFO_ENDPOINT = window.env.USER_INFO_ENDPOINT || 'https://graph.microsoft.com/oidc/userinfo'

export const OidcConfigAzure = {
  client_id: TEST_CLIENT_ID,
  redirect_uri: LOCAL_REACT_APP_PREFIX + '/',
  post_logout_redirect_uri: LOCAL_REACT_APP_PREFIX,
  response_type: 'code',
  scope: 'openid profile email',
  authority: PREFIX_OIDC_SERVER + '/oauth2/v2.0/authorize',
  metadata: {
    issuer: PREFIX_OIDC_SERVER,
    authorization_endpoint: PREFIX_OIDC_SERVER + '/oauth2/v2.0/authorize',
    token_endpoint: PREFIX_OIDC_SERVER + '/oauth2/v2.0/token',
    userinfo_endpoint: USER_INFO_ENDPOINT,
    end_session_endpoint: `${PREFIX_OIDC_SERVER}/oauth2/v2.0/logout?post_logout_redirect_uri=${LOCAL_REACT_APP_PREFIX}`,
    jwks_uri: PREFIX_OIDC_SERVER + '/discovery/v2.0/keys',
  },
}
