import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import LinearProgress from '@material-ui/core/LinearProgress'

import PrivateRoute from './layout/PrivateRoute'

import './App.css'
import { ConventionsList } from './Components/conventions-collectives/conventions-collectives-list'
import { AddConventionForm } from './Components/conventions-collectives/add-convention-form'
import { ConventionDetail } from './Components/conventions-collectives/convention-collective-detail'
import { ComponentValueDetail } from './Components/components/component-value-detail'
import { ThemeDetail } from './Components/themes/theme-detail'
import ImportData from './Components/import/ImportData'
import { Tableur } from './Components/tableur/tableur'
import { HistoriqueDetailPage } from './Components/historiques/historique-detail-page'
import { Workflows } from './Components/workflows/workflows'
import { WorkflowModelDetail } from './Components/workflows/detail/workflowModelCreation'
import { BoSettings } from './Components/settings/bo-settings'
import { AuthWrapper } from './pages/auth-wrapper'

const HomeScreen = React.lazy(() => import('./pages/HomeScreen'))
const Inputs = React.lazy(() => import('./Components/Inputs/Inputs'))
const AddInput = React.lazy(() => import('./Components/Inputs/AddInput'))
const VersionsList = React.lazy(() => import('./Components/versions/administration/versions-list'))
const VersionListDetail = React.lazy(() => import('./Components/versions/administration/version-edit'))
const ThemesList = React.lazy(() => import('./Components/themes/administration/themes-list'))
const ThemeListDetail = React.lazy(() => import('./Components/themes/administration/theme-edit'))
const ThemeListCreate = React.lazy(() => import('./Components/themes/administration/theme-create'))
const ReferentielsList = React.lazy(() => import('./Components/Referentiels/administration/referentiels-list'))
const ReferentielCreate = React.lazy(() => import('./Components/Referentiels/administration/referentiel-create'))
const ReferentielDetail = React.lazy(() => import('./Components/Referentiels/administration/referentiel-detail'))
const ComponentList = React.lazy(() => import('./Components/components/administration/components-list'))
const ComponentListDetail = React.lazy(() => import('./Components/components/administration/component-edit'))
const ComponentListCreate = React.lazy(() => import('./Components/components/administration/component-create'))
const Outputs = React.lazy(() => import('./Components/Outputs/Outputs'))
const AddOutput = React.lazy(() => import('./Components/Outputs/AddOutput'))
const AccessList = React.lazy(() => import('./Components/access/access-list'))
const AccessCreate = React.lazy(() => import('./Components/access/access-create'))
const AccessEdit = React.lazy(() => import('./Components/access/access-edit'))
const TasksList = React.lazy(() => import('./Components/task/tasks-list'))
const TaskEdit = React.lazy(() => import('./Components/task/task-edit'))

const App = () => {
  return (
    <AuthWrapper>
      <Router basename="/">
        <Suspense fallback={<LinearProgress />}>
          <Switch>
            <Route exact path="/" component={HomeScreen} />
            <PrivateRoute path="/tableur/:idcc/theme/:theme" component={Tableur} />
            <PrivateRoute exact path="/tableur" component={Tableur} />
            <PrivateRoute exact path="/settings" component={BoSettings} />
            <PrivateRoute exact path="/tableur/:idcc" component={Tableur} />
            <PrivateRoute exact path="/conventions-collectives" component={ConventionsList} />
            <PrivateRoute exact path="/add-convention" component={AddConventionForm} />
            <PrivateRoute exact path="/detail-convention/:idcc/historiques/:nom" component={HistoriqueDetailPage} />
            <PrivateRoute exact path="/detail-convention/:id" component={ConventionDetail} />
            <PrivateRoute exact path="/detail-convention/:idcc/theme/:themeName" component={ThemeDetail} />
            <PrivateRoute
              exact
              path="/detail-convention/:idcc/theme/:themeName/:role/:componentName"
              component={ComponentValueDetail}
            />
            <PrivateRoute exact path="/inputs" component={Inputs} />
            <PrivateRoute exact path="/add-input" component={AddInput} />
            <PrivateRoute exact path="/edit-input/:id" component={AddInput} />
            <PrivateRoute exact path="/outputs" component={Outputs} />
            <PrivateRoute exact path="/add-output" component={AddOutput} />
            <PrivateRoute exact path="/edit-output/:id" component={AddOutput} />
            <PrivateRoute exact path="/referentiels" component={ReferentielsList} />
            <PrivateRoute exact path="/add-referentiel" component={ReferentielCreate} />
            <PrivateRoute exact path="/detail-referentiel/:id" component={ReferentielDetail} />
            <PrivateRoute exact path="/themes" component={ThemesList} />
            <PrivateRoute exact path="/versions" component={VersionsList} />
            <PrivateRoute exact path="/detail-version/:id" component={VersionListDetail} />
            <PrivateRoute exact path="/detail-theme/:id" component={ThemeListDetail} />
            <PrivateRoute exact path="/add-theme" component={ThemeListCreate} />
            <PrivateRoute exact path="/composants" component={ComponentList} />
            <PrivateRoute exact path="/detail-composant/:id" component={ComponentListDetail} />
            <PrivateRoute exact path="/add-composant" component={ComponentListCreate} />
            <PrivateRoute exact path="/import-data" component={ImportData} />
            <PrivateRoute exact path="/workflows" component={Workflows} />
            <PrivateRoute exact path="/workflows/model/create" component={WorkflowModelDetail} />
            <PrivateRoute exact path="/workflows/model/create/:nom" component={WorkflowModelDetail} />
            <PrivateRoute exact path="/access" component={AccessList} />
            <PrivateRoute exact path="/add-access" component={AccessCreate} />
            <PrivateRoute exact path="/detail-access/:id" component={AccessEdit} />
            <PrivateRoute exact path="/tasks" component={TasksList} />
            <PrivateRoute exact path="/detail-task/:id" component={TaskEdit} />
          </Switch>
        </Suspense>
      </Router>
    </AuthWrapper>
  )
}

export default App
