import User from '../constants/user'

export const SET_USER = 'paye-bo/user/SET_USER'

const LOCALHOST_3000 = 'localhost:3000'
const OMDD_LOCAL_3000 = 'omdd.local:3000'
export default function user(
  state = {
    user: User.NULL,
    testmode: window.location.host === LOCALHOST_3000 || window.location.host === OMDD_LOCAL_3000,
  },
  action
) {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.value }
    default:
      return state
  }
}
