import React, { useEffect } from 'react'

import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useHistory, useLocation } from 'react-router-dom'

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    padding: 16,
    [breakpoints.up('sm')]: {
      padding: 24,
      //maxWidth: 500,
      margin: 'auto',
    },
    [breakpoints.up('md')]: {
      //maxWidth: 700,
    },
  },
}))

const Accueil = () => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)

    console.log('queryParams', queryParams)
    if (queryParams.size > 0) {
      location.search = {}
      history.push('/')
    }
  }, [])

  return (
    <div className={classes.root}>
      <Typography align="center" variant="h1">
        Page d'accueil de l'outil de modélisation
      </Typography>
    </div>
  )
}

export default Accueil
