import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Accueil from './Accueil'
import AppLayout from '../layout/AppLayout'
import { logInWithOIDCToken } from '../store/actions/login-state-action'
import { useAuth } from 'react-oidc-context'
import { UserManager } from 'oidc-client-ts'
import { OidcConfigAzure } from './OidcConfigAzure'
import { OidcConfigPing } from './OidcConfigPing'

//export const OidcConfig = OidcConfigPing
export const OidcConfig = OidcConfigAzure

export const userManager = new UserManager(OidcConfig)

export async function signinRedirect() {
  try {
    return await userManager.signinRedirect()
  } catch (error) {
    console.error(`Erreur lors de la connexion : ${error}`)
  }
}

const HomeScreen = () => {
  const loginState = useSelector(state => state.loginState)
  const testmode = useSelector(state => state.user.testmode)
  const loggedIn = loginState === 'success'
  const dispatch = useDispatch()

  const auth = useAuth()

  useEffect(() => {
    if (!auth.isLoading && (!auth.user || auth.user.expired)) {
      signinRedirect()
    } else if (auth.isAuthenticated) {
      dispatch(logInWithOIDCToken(auth.user.id_token, () => auth.removeUser()))
    }
  }, [auth])

  useEffect(() => {
    if (loginState === 'logged-out' && auth.isAuthenticated) {
      auth.removeUser()
    }
  }, [loginState, auth.isAuthenticated])

  let authenticated = useMemo(() => auth.isAuthenticated, [auth.isAuthenticated])
  return authenticated && loggedIn ? (
    <AppLayout>
      <Accueil />
    </AppLayout>
  ) : (
    'Chargement...'
  )
}

HomeScreen.propTypes = {}

export default HomeScreen
