import React from 'react'
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import AppLayout from './AppLayout'
import { useAuth } from 'react-oidc-context'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const loggedIn = useSelector(state => state.loginState === 'success')
  const history = useHistory()
  const location = useLocation()
  const shouldPush = history.action === 'PUSH'

  const auth = useAuth()
  if (!auth.isAuthenticated && auth.user && auth.user.expired) {
    auth.signinSilent()
    return null
  }
  return (
    <Route
      {...rest}
      render={props =>
        loggedIn ? (
          <AppLayout>
            <Component {...props} />
          </AppLayout>
        ) : (
          <Redirect push={shouldPush} to={{ pathname: '/', state: { from: location } }} />
        )
      }
    />
  )
}

export default PrivateRoute
